import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import report from './modules/report'
import versionControl from './modules/version-control'
import accountSettings from './modules/account-settings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    report,
    versionControl,
    accountSettings,
  },
  state: {
    controls: {
      showDrawer: true,
    },
    snackbar: {
      show: false,
      message: null,
      color: null,
    },
  },
  mutations: {
    toggleDrawer(state, value) {
      state.controls.showDrawer = value
    },
    HIDE_SNACKBAR(state) {
      state.snackbar.show = false
    },
    SET_SNACKBAR(state, payload) {
      state.snackbar = payload
    },
  },
  actions: {
    showErrorSnackbar({ commit }, message) {
      commit('SET_SNACKBAR', {
        show: true,
        message: message || 'Something went wrong!',
        color: 'error',
      })
    },
    showSuccessSnackbar({ commit }, message) {
      commit('SET_SNACKBAR', {
        show: true,
        message: message || 'Success!',
        color: 'success',
      })
    },
  },
})
