import Api from '@/services/api'

export default {
  namespaced: true,
  state: {
    isLoading: false,
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload
    },
  },
  actions: {
    async getVerificationToken({ commit }, payload) {
      commit('SET_LOADING', true)
      try {
        return Api.post(`/auth/account/verification-token`, payload)
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async changePassword({ commit }, payload) {
      commit('SET_LOADING', true)
      try {
        return Api.post(`/auth/change/password`, payload, {
          headers: {
            'verification-token': payload.token,
          },
        })
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
